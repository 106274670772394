.navbar {
  position: fixed;
  width: 100%;
  transition: background-color 0.5s ease;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  z-index: 2000;
}

.logo {
  content: url(../../../imgs/marview_logo.png);
  height: 60%;
  position: absolute;
  left: 10%;
}

.logo.transparent {
  content: url(../../../imgs/marview_white_logo.png);
  height: 60%;
}

.nav-side-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 0 10px;
  position: absolute;
  right: 10%;
  z-index: 1000;
}

.nav-items {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 1rem;
}

.nav-links {
  font-weight: 600;
  text-decoration: None;
  padding: 8px 20px;
  transition: all 0.5s ease-out;
}

.nav-link-map {
  font-weight: 600;
  text-decoration: None;
  padding: 8px 20px;
  transition: all 0.2s ease-out;
  border: 1pt solid #fff;
  border-radius: 25px;
}

.nav-link-map:hover {
  background-color: #4d4dff !important;
  border: 1pt solid #4d4dff;
  color: #fff !important;
}

.nav-links:hover {
  color: #4d4dff !important;
}

.nav-links:hover > .fa-caret-down {
  color: #4d4dff;
}

.profile-icon {
  color: #00008b;
}

.fa-bars {
  color: #00008b;
}

.fa-caret-down {
  color: #00008b;
  font-size: 80%;
  transition: all 0.5s ease-out;
}

.nav-links-button {
  display: none;
}

.menu-icon {
  display: none;
}

.link-color {
  color: white;
}

@media screen and (max-width: 768px) {
  .nav-side-menu {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: calc(100vh - 60px);
    position: absolute;
    margin-top: 0;
    top: 60px;
    border: 2px solid #ececee;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;
  }

  .nav-side-menu.start {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    color: #00008b;
    display: table;
    transition: 0s;
  }

  .nav-links:hover {
    color: #4d4dff;
  }

  .logo {
    position: absolute;
    /* transform: translate(25%, 50%); */
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 1rem;
    /* transform: translate(-100%, 60%); */
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #00008b;
    font-size: 2rem;
  }

  .nav-items {
    height: auto;
    align-self: center;
    padding: 1.5rem;
  }

  .link-color {
    color: #00008b;
}
}
